import { request, API } from '@/utils/request';

//获取列表
export function getList(data) {
  return request({
    url: API.host + '/api/user/page',
    method: 'post',
    data: data || {}
  })
}

//数据同步
export function userSync(data) {
  return request({
    url: API.host + '/api/user/sync',
    method: 'get'
  })
}

//查看详情
export function userInfo(id) {
  return request({
    url: API.host + `/api/user/info/${id}`,
    method: 'get'
  })
}

//绑定角色
export function bangDingRole(data) {
  return request({
    url: API.host + '/api/user/bangDing/role',
    method: 'post',
    data: data || {}
  })
}

//角色详情
export function getRolesInfo(id) {
  return request({
    url: API.host + `/api/role/info/${id}`,
    method: 'get'
  })
}

//删除角色
export function deleteRoles(id) {
  return request({
    url: API.host + `/api/role/delete/{id}`,
    method: 'get'
  })
}

//添加/修改角色
export function addRoles(data) {
  return request({
    url: API.host + '/api/role/edit',
    method: 'post',
    data: data || {}
  })
}


export function commonRequest(data) {
  return request({
    url: API.host + API[data.name],
    method: 'post',
    data: data.data || {},
    showloading: data.showloading
  })
}
export function commonRequestGET(data) {
  return request({
    url: API.host + API[data.name],
    method: 'get',
    params: data.data || {},
    showloading: data.showloading
  })
}
export function downLoad(data) {
  return request({
    url: API.host + API[data.name],
    method: 'get',
    data: data.data || {},
    responseType: 'blob',
    showloading: data.showloading
  })
}

export function sendCode(data) {
  return request({
    url: API.host + '/login/code',
    method: 'get',
    params: data || {}
  })
}
<template>
	<div class="navbar">
		<hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
		<breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
		<div class="right-menu">
			<el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
				<div class="avatar-wrapper">
					<img src="@/assets/images/default_avant.png" class="user-avatar">
					<span class="user-name">{{$store.state.user.userInfo.name}}</span>
					<i class="el-icon-caret-bottom user-icon" />
				</div>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item divided @click.native="logout">
						<span style="display:block;">退出登录</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import Breadcrumb from '@/components/Breadcrumb'
	import Hamburger from '@/components/Hamburger'
	import ErrorLog from '@/components/ErrorLog'
	import Screenfull from '@/components/Screenfull'
	import SizeSelect from '@/components/SizeSelect'
	import LangSelect from '@/components/LangSelect'
	import Search from '@/components/HeaderSearch'
	import { removeToken } from '@/utils/auth'
	export default {
		components: {
			Breadcrumb,
			Hamburger,
			ErrorLog,
			Screenfull,
			SizeSelect,
			LangSelect,
			Search
		},
		computed: {
			...mapGetters([
				'sidebar',
				'avatar',
				'device'
			])
		},
		created() {
			console.log(this.$store.state);
		},
		methods: {
			toggleSideBar() {
				this.$store.dispatch('app/toggleSideBar')
			},
			async logout() {
				await this.$store.dispatch('user/logout')
				// this.$router.push(`/login?redirect=${this.$route.fullPath}`)
				removeToken();
				this.$router.push(`/login`)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.navbar {
		height: 50px;
		overflow: hidden;
		position: relative;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

		.hamburger-container {
			line-height: 46px;
			height: 100%;
			float: left;
			cursor: pointer;
			transition: background .3s;
			-webkit-tap-highlight-color: transparent;

			&:hover {
				background: rgba(0, 0, 0, .025)
			}
		}

		.breadcrumb-container {
			float: left;
		}

		.errLog-container {
			display: inline-block;
			vertical-align: top;
		}

		.right-menu {
			float: right;
			height: 100%;
			line-height: 50px;

			&:focus {
				outline: none;
			}

			.right-menu-item {
				display: inline-block;
				padding: 0 8px;
				height: 100%;
				font-size: 18px;
				color: #5a5e66;
				vertical-align: text-bottom;

				&.hover-effect {
					cursor: pointer;
					transition: background .3s;

					&:hover {
						background: rgba(0, 0, 0, .025)
					}
				}
			}

			.avatar-container {
				margin-right: 30px;

				.avatar-wrapper {
					display: flex;
					align-items: center;
					margin-top: 5px;
					position: relative;

					.user-avatar {
						cursor: pointer;
						width: 40px;
						height: 40px;
						border-radius: 10px;
					}
					.user-name{
						font-weight: 600;
						margin: 0 8px;
					}
					.user-icon{
						font-size: 20px;
						cursor: pointer;
						position: absolute;
						right: -20px;
						// top: 25px;
					}
				}
			}
		}
	}
</style>

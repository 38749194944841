exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#5e8ff6",
	"menuHover": "rgba(0,0,0,0)",
	"subMenuBg": "rgba(0,0,0,0)",
	"subMenuHover": "rgba(0,0,0,0)",
	"sideBarWidth": "210px"
};
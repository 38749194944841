<template>
	<app-main />
</template>

<script>
	import {AppMain} from './components';

	export default {
		name: 'Empty',
		components: {AppMain,},
		computed: {

		},
		methods: {

		}
	};
</script>

<style lang="scss" scoped>

</style>
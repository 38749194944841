import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css
import '@/assets/iconfont/iconfont.css'
import './static/js/flexible'
import App from './App'
import store from './store'
import router from './router'
import util from './utils/myTool'
import i18n from './lang' // internationalization
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters
import { commonRequest, commonRequestGET } from '@/api/user'
import hlEmpty from '@/components/hlCompnents/empty.vue'
import pagination from '@/components/Pagination/index.vue'

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
Vue.component('hlEmpty',hlEmpty)
Vue.component('hlPagination',pagination)

//import it in your project At your entry point
// import JsonEditor from 'vue-json-edit'
// Vue.use(JsonEditor)


// cm-setting.js
// 组件样式
import "codemirror/lib/codemirror.css";
// 主题
import "codemirror/theme/3024-day.css"; // 引入主题样式，根据设置的theme的主题引入
import "codemirror/theme/ayu-mirage.css";
import "codemirror/theme/monokai.css";
import 'codemirror/theme/rubyblue.css';
// html代码高亮
import "codemirror/mode/htmlmixed/htmlmixed.js"; 
// 语言模式
import 'codemirror/mode/javascript/javascript.js'


/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  const { mockXHR } = require('../mock')
  mockXHR()
}

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false
Vue.prototype.commonRequest = commonRequest
Vue.prototype.commonRequestGET = commonRequestGET
Vue.prototype.$tool = util

import  VueQuillEditor from 'vue-quill-editor'
// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';
import subLayout from '@/layout/components/AppMain';
import Empty from '@/layout/empty';
/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
	{path: '/redirect',component: Layout,hidden: true,
		children: [
			{path: '/redirect/:path(.*)',component: () => import('@/views/redirect/index')}, 
		]
	},
	{path: '/login',name:'login',component: () => import('@/views/login/index'),hidden: true},
	{path: '/auth-redirect',component: () => import('@/views/login/auth-redirect'),hidden: true},
	{path: '/',component: Layout,redirect: '/home',meta: {title: '首页',icon: 'el-icon-s-home'},
		children: [
			{path: '/home',component: () => import('@/views/home/index'),name: 'home',meta: {title: '首页',icon: 'el-icon-s-home',affix: true}},
		]
	},
	{path: '/404',component: () => import('@/views/404'),hidden: true},
	// 404 page must be placed at the end !!!
	{path: '*',redirect: '/404',hidden: true},
];

export const asyncRoutes = [
	{path: '/app',component: Layout,name: 'app',title: '应用管理',meta: {title: '应用管理',icon: 'el-icon-mobile-phone'},redirect: 'applist',
		children: [
			{path: 'applist',component: () => import('@/views/appManage/applist'),name: 'applist',title: '应用列表',meta: {title: '应用列表',icon: 'el-icon-mobile-phone'}}, 
			// {path: 'appprojectlist',component: () => import('@/views/projectManage/projectlist'),name: 'appprojectlist',title: '应用项目',meta: {title: '应用项目',icon: 'el-icon-help'}, hidden: true},
		]
	},
	{path: '/project',component: Layout,name: 'project',title: '项目管理',meta: {title: '项目管理',icon: 'el-icon-help'},redirect: 'projectlist',
		children: [
			{path: 'projectlist',component: () => import('@/views/projectManage/projectlist'),name: 'projectlist',title: '项目列表',meta: {title: '项目列表',icon: 'el-icon-help'}},
			{path: 'scenelist',component: () => import('@/views/projectManage/scenelist'),name: 'scenelist',title: '项目场景',meta: {title: '项目场景',icon: 'el-icon-help'}, hidden: true},
			{path: 'sceneresourceslist',component: () => import('@/views/resourcesManage/resourceslist'),name: 'sceneresourceslist',title: '场景资源',meta: {title: '场景资源',icon: 'el-icon-help'}, hidden: true},
		]
	},
	{path: '/resources',component: Layout,name: 'resources',title: '资源管理',meta: {title: '资源管理',icon: 'el-icon-document'},redirect: 'resourceslist',
		children: [
			{path: 'resourceslist',component: () => import('@/views/resourcesManage/resourceslist'),name: 'resourceslist',title: '资源列表',meta: {title: '资源列表',icon: 'el-icon-document'}}, 
		]
	},
	{path: '/system',component: Layout,name: 'system',title: '系统管理',meta: {title: '系统管理',icon: 'el-icon-setting'},
		children: [
			{path: 'userlist',component: () => import('@/views/systemManage/userlist'),name: 'userlist',title: '用户列表',meta: {title: '用户列表',icon: ''}}, 
			{path: 'rolelist',component: () => import('@/views/systemManage/rolelist'),name: 'rolelist',title: '角色列表',meta: {title: '角色列表',icon: ''}}, 
			{path: 'organization',component: () => import('@/views/systemManage/organization'),name: 'organization',title: '组织架构',meta: {title: '组织架构',icon: ''}}
		]
	},
];

const createRouter = () =>
	new Router({
		mode: 'history',
		//环境切换配置
		base: process.env.NODE_ENV != 'local' ? '' : '',
		scrollBehavior: () => ({
			y: 0
		}),
		// routes: constantRoutes,
		routes: [...constantRoutes,...asyncRoutes],
	});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}

export default router;

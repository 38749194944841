
import store from '@/store';
import router from '@/router';
export default {
  filterNanme(options, type) {
    var filterData = options.filter(item => {
      return item.value == type;
    });
    return filterData.length ? filterData[0].label : '';
  },
  filterIcon(options, type) {
    var filterData = options.filter(item => {
      return item.value == type;
    });
    return filterData.length ? filterData[0].icon : '';
  },
  createMap(options) {
    var map = {};
    options.forEach(item => {
      map[item.value] = item.label;
    });
    return map;
  },
  // sensitiveLevelMap() {
  //   return this.createMap(sensitiveLevelOptions);
  // },
  setTagsViewTitle(txt) {
    const title = txt;
    var route = Object.assign({}, router, { title: `${title}` });
    store.dispatch('tagsView/updateVisitedView', route);
    document.title = `${title}`;
  },
};

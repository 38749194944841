
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router' 
import {login,loginWithToken,getUserInfo,getNewsTypes,getActivityTypes} from '@/api'

const state = {
  token: getToken(),
  name: '',
  avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
  introduction: '',
  roles: [],
  userId:'',
  userInfo:{},
  userList: [],
  newsTypes: [],
  activityTypes: [],
  menuData: []
}

const mutations = {
  SET_AEPT: (state, apetRow)=>{
    state.apetRow = apetRow
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USER: (state, data) => {
    localStorage.setItem("A_token",data.token)
    localStorage.setItem("userId",data.userId)
    state.userId = data.userId
  },
  SET_UserInfo:(state,data)=>{
    state.userInfo = data
  },
  SET_UserList:(state,data)=>{
    state.userList = data
  },
  SET_NewsTypes:(state,data)=>{
    state.newsTypes = data
  },
  SET_ActivityTypes:(state,data)=>{
    state.activityTypes = data
  },
  SET_MenuData:(state,data)=>{
    state.menuData = data || []
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const {username,password} = userInfo
    return new Promise((resolve, reject) => {
      login({identifier:username,password:password}).then((res)=>{
		console.log(res);
        commit('SET_TOKEN', res.jwt)
        setToken(res.jwt)
        resolve()
      })
    })
  },
  loginWithToken({ commit }, userInfo) {
    const {token,sign} = userInfo
    return new Promise((resolve, reject) => {
      loginWithToken({token:token,sign:sign}).then((res)=>{
        commit('SET_TOKEN', res.data.token)
        setToken(res.data.token)
        resolve()
      })
    })
  },
  getDirc({ commit, state }){
    return Promise.all(
		[
			new Promise((resolve, reject) => {
				getUserInfo({}).then((res)=>{
					commit('SET_UserInfo', res.data)
					// commit('SET_MenuData', res.data.roles[0]?.menuData || [])
					let arr = [];
					res.data.roles.forEach((item)=>{
						arr = arr.concat(item.menuData)
					});
					commit('SET_MenuData', arr || []);
					resolve()
				}).catch(error => {
					reject(error)
				});
			}),
			// new Promise((resolve, reject) => {
			// 	getUserList({}).then((res)=>{
			// 		commit('SET_UserList', res.data)
			// 		resolve()
			// 	}).catch(error => {
			// 		reject(error)
			// 	});
			// }),
		]
    ).then((result) => {
  
    }).catch((error) => {
  
    })
  },
  // 同步用户信息
  queryUserInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo({}).then((res)=>{
        var arr = [];
        res.data.roles.forEach((item)=>{
          arr = arr.concat(item.menuData)
        })
        resolve(arr)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_ROLES', ['admin']),
      resolve({roles:['admin']})
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resetRouter()

      // reset visited views and cached views
      // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
      dispatch('tagsView/delAllViews', null, { root: true })

      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
	console.log(accessRoutes,2222222222)
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

<template>
	<el-breadcrumb class="app-breadcrumb" separator="/">
		<transition-group name="breadcrumb">
			<el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
				<span v-if="item.redirect==='noRedirect'||index==levelList.length-1" class="no-redirect">{{ generateTitle(item.meta.title) }}</span>
				<!-- @click.prevent="handleLink(item)" -->
				<a v-else>{{ generateTitle(item.meta.title) }}</a>
			</el-breadcrumb-item>
		</transition-group>
	</el-breadcrumb>
</template>

<script>
	import {generateTitle} from '@/utils/i18n'
	import pathToRegexp from 'path-to-regexp'

	export default {
		data() {
			return {
				levelList: null
			}
		},
		watch: {
			$route(route) {
				// if you go to the redirect page, do not update the breadcrumbs
				if (route.path.startsWith('/redirect/')) {
					return
				}
				this.getBreadcrumb()
			}
		},
		created() {
			this.getBreadcrumb()
		},
		methods: {
			generateTitle,
			getBreadcrumb() {
				// only show routes with meta.title
				let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
				const first = matched[0]

				if (!this.isDashboard(first)) {
					matched = [
						// { path: '/dashboard', meta: { title: 'dashboard' }}
					].concat(matched)
				}
				this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
				console.log(this.levelList[1].name);
				// {name:"projectlist",path:"/project/projectlist"}
				// {name:"scenelist",path:"/project/scenelist"}
				// {name:"sceneresourceslist",path:"/project/sceneresourceslist"}
				if( this.levelList[1].name==='sceneresourceslist' ){
					this.levelList = [this.levelList[0],{name:"projectlist",path:"/project/projectlist",meta:{title:'项目列表'}},{name:"scenelist",path:"/project/scenelist",meta:{title:'项目场景'}},this.levelList[1]]
				}else if( this.levelList[1].name==='scenelist' ){
					this.levelList = [this.levelList[0],{name:"projectlist",path:"/project/projectlist",meta:{title:'项目列表'}},this.levelList[1]]
				}
			},
			isDashboard(route) {
				const name = route && route.name
				if (!name) {
					return false
				}
				return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
			},
			pathCompile(path) {
				// To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
				const {params} = this.$route
				var toPath = pathToRegexp.compile(path)
				return toPath(params)
			},
			handleLink(item) {
				const {redirect,path} = item
				if (redirect) {
					this.$router.push(redirect)
					return
				}
				this.$router.push(this.pathCompile(path))
			}
		}
	}
</script>

<style lang="scss" scoped>
	.app-breadcrumb.el-breadcrumb {
		display: inline-block;
		font-size: 14px;
		line-height: 50px;
		margin-left: 8px;

		.no-redirect {
			color: #97a8be;
			cursor: text;
		}
	}
</style>
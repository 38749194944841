<template>
    <div class="empty">
        <img :style="{width:width}" src="../../assets/images/empty.png" alt="">
        <div class="text">暂无数据</div>
    </div>
</template>
<script>
    export default{
        props:{
            width:{
                type:String,
                default:'234px'
            }
        },
        data(){
            return{

            }
        }
    }
</script>

<style lang="less" scoped>
    .empty{
        text-align: center;
        padding: 20px 0 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        img{
            width: 234px;
        }
    }
</style>
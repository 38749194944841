
import { request, API } from '@/utils/request';
//登录
export function login(data) {
  return request({
    url: API.host + '/api/auth/local',
    method: 'post',
    data: data || {}
  })
}

//应用管理-所有应用
export function getAllApplications(params) {
	let urlStrArr = ['/api/applications'];
	for(let key in params){
		if( params[key]!==null && params[key]!=='' ){
			if( key=='page' || key=='pageSize' ){
				urlStrArr.push(urlStrArr.includes('?')?'&':'?');
				urlStrArr.push(`pagination[${key}]=${params[key]}`);
			}else if( key=='populate' ){
				urlStrArr.push(urlStrArr.includes('?')?'&':'?');
				urlStrArr.push(`populate=${params[key]}`);
			}else{
				urlStrArr.push(urlStrArr.includes('?')?'&':'?');
				if( key=='name' ){
					urlStrArr.push(`filters[${key}][$contains]=${params[key]}`);
				}else{
					urlStrArr.push(`filters[${key}]=${params[key]}`);
				}
			}
		}
	}
	return request({
		url: API.host + urlStrArr.join('') + '&sort[0]=id:desc',
		method: 'get',
	});
}
//应用管理-新增应用
export function addApplications(data) {
  return request({
    url: API.host + '/api/applications',
    method: 'post',
    data: data || {}
  })
}
//应用管理-更新应用
export function updataApplications(data) {
  return request({
    url: API.host + `/api/applications/${data.data.id}`,
    method: 'put',
    data: data || {}
  })
}
//应用管理-应用详情
export function getIdApplications(params) {
  return request({
    url: API.host + `/api/applications/${params.id}?populate=*`,
    method: 'get'
  })
}
//应用管理-删除应用
export function deleteApplications(data) {
  return request({
    url: API.host + `/api/applications/${data.id}`,
    method: 'delete',
  })
}


//项目管理-所有项目
export function getAllProjects(params) {
  let urlStrArr = ['/api/projects'];
  for(let key in params){
  	if( params[key]!==null && params[key]!=='' ){
  		if( key=='page' || key=='pageSize' ){
  			urlStrArr.push(urlStrArr.includes('?')?'&':'?');
  			urlStrArr.push(`pagination[${key}]=${params[key]}`);
  		}else if( key=='populate' ){
			urlStrArr.push(urlStrArr.includes('?')?'&':'?');
			urlStrArr.push(`populate=${params[key]}`);
		}else{
  			urlStrArr.push(urlStrArr.includes('?')?'&':'?');
  			if( key=='name' ){
  				urlStrArr.push(`filters[${key}][$contains]=${params[key]}`);
  			}else{
  				urlStrArr.push(`filters[${key}]=${params[key]}`);
  			}
  		}
  	}
  }
  return request({
  	url: API.host + urlStrArr.join('') + '&sort[0]=id:desc',
  	method: 'get',
  });
}
//项目管理-新增项目
export function addProjects(data) {
  return request({
    url: API.host + '/api/projects',
    method: 'post',
    data: data || {}
  })
}
//项目管理-更新项目
export function updataProjects(data) {
  return request({
    url: API.host + `/api/projects/${data.data.id}`,
    method: 'put',
    data: data || {}
  })
}
//项目管理-项目详情
export function getIdProjects(params) {
  return request({
    url: API.host + `/api/projects/${params.id}?populate=*`,
    method: 'get'
  })
}
//项目管理-删除项目
export function deleteProjects(data) {
  return request({
    url: API.host + `/api/projects/${data.id}`,
    method: 'delete',
  })
}


//项目场景-场景列表
export function getProjectsScenes(params) {
  let urlStrArr = ['/api/scenes'];
  for(let key in params){
  	if( params[key]!==null && params[key]!=='' ){
  		if( key=='page' || key=='pageSize' ){
  			urlStrArr.push(urlStrArr.includes('?')?'&':'?');
  			urlStrArr.push(`pagination[${key}]=${params[key]}`);
  		}else{
  			urlStrArr.push(urlStrArr.includes('?')?'&':'?');
  			if( key=='name' ){
  				urlStrArr.push(`filters[${key}][$contains]=${params[key]}`);
  			}else{
  				urlStrArr.push(`filters[${key}]=${params[key]}`);
  			}
  		}
  	}
  }
  return request({
  	url: API.host + urlStrArr.join(''),
  	method: 'get',
  });
}
//项目场景-场景数据1
export function getProjectsScenesData1(params) {
  return request({
    url: API.host + `/api/projects/${params.id}?populate[scenes][populate][0]=banner`,
    method: 'get'
  })
}
//项目场景-场景数据2
export function getProjectsScenesData2(params) {
  return request({
	url: API.host + `/api/scenes?filters[project][id][$eq]=${params.id}&sort[0]=sort&populate=*`,
    method: 'get'
  })
}
//项目场景-新增场景
export function addProjectsScenes(data) {
  return request({
    url: API.host + '/api/scenes',
    method: 'post',
    data: data || {}
  })
}
//项目场景-更新场景
export function updataProjectsScenes(data) {
  return request({
    url: API.host + `/api/scenes/${data.data.id}`,
    method: 'put',
    data: data || {}
  })
}
//项目场景-项目场景
export function getIdProjectsScenes(params) {
  return request({
    url: API.host + `/api/scenes/${params.id}?populate[banner]=true&populate[background_image]=true&populate[assets][populate]=src`,
    method: 'get'
  })
}
//项目场景-删除场景
export function deleteProjectsScenes(data) {
  return request({
    url: API.host + `/api/scenes/${data.id}`,
    method: 'delete',
  })
}
// //项目场景-项目场景排序
// export function projectsScenesSort(id,sortArr) {
// 	let urlStrArr = [`/api/scenes/${id}`];
// 	sortArr.forEach((item,index)=>{
// 		urlStrArr.push(urlStrArr.includes('?')?'&':'?');
// 		urlStrArr.push(`sort[${index}]=${item.id}`);
// 	});
// 	return request({
// 		url: API.host + urlStrArr.join(''),
// 		method: 'get',
// 	});
// }

//资源管理-资源列表
export function getAllAssets(params) {
  let urlStrArr = ['/api/assets'];
  for(let key in params){
  	if( params[key]!==null && params[key]!=='' ){
  		if( key=='page' || key=='pageSize' ){
  			urlStrArr.push(urlStrArr.includes('?')?'&':'?');
  			urlStrArr.push(`pagination[${key}]=${params[key]}`);
  		}else if( key=='populate' ){
  			urlStrArr.push(urlStrArr.includes('?')?'&':'?');
  			urlStrArr.push(`populate=${params[key]}`);
  		}else{
  			urlStrArr.push(urlStrArr.includes('?')?'&':'?');
  			if( key=='name' ){
  				urlStrArr.push(`filters[${key}][$contains]=${params[key]}`);
  			}else{
  				urlStrArr.push(`filters[${key}]=${params[key]}`);
  			}
  		}
  	}
  }
  return request({
  	url: API.host + urlStrArr.join('') + '&sort[0]=id:desc&filters[static][$eq]=false&filters[base_asset_id]=0',
  	method: 'get',
  });
}
//项目场景资源-资源数据
export function getProjectsScenesAssetsData(params) {
  return request({
    url: API.host + `/api/scenes/${params.id}?populate=*`,
    method: 'get'
  })
}
//资源管理-新增资源
export function addAssets(data) {
  return request({
    url: API.host + '/api/assets',
    method: 'post',
    data: data || {}
  })
}
//资源管理-更新资源
export function updataAssets(data) {
  return request({
    url: API.host + `/api/assets/${data.data.id}`,
    method: 'put',
    data: data || {}
  })
}
//资源管理-资源详情
export function getIdAssets(params) {
  return request({
    url: API.host + `/api/assets/${params.id}?populate=*`,
    method: 'get'
  })
}
//资源管理-删除资源
export function deleteAssets(data) {
  return request({
    url: API.host + `/api/assets/${data.id}`,
    method: 'delete',
  })
}

//资源管理-父级id查看子级数据
export function getChildAssets(params) {
  return request({
  	url: API.host + `/api/assets?filters[base_asset_id][$eq]=${params.id}&populate=*`,
  	method: 'get',
  });
}

// 获取指示器列表
export function getIndicatorData(params) {
  return request({
    url: API.host + `/api/assets?filters[static][$eq]=true&filters[type][$eq]=Anchor&populate=*`,
    method: 'get'
  })
}

// 获取环境贴图列表
export function getBinData(params) {
  return request({
    url: API.host + `/api/assets?filters[static][$eq]=true&filters[type][$eq]=EnvMap&populate=*`,
    method: 'get'
  })
}





export function loginWithToken(data) {
  return request({
    url: API.host + `/api/login/loginWithToken?token=${data.token}&sign=${data.sign}`,
    method: 'post',
    data: data || {}
  })
}

// 获取账号基本信息
export function getUserInfo() {
  return request({
    url: API.host + `/api/user/base`,
    method: 'get'
  })
}

//组织结构
export function department_tree(data) {
  return request({
    url: API.host + '/api/department/tree',
    method: 'get'
  })
}
export function department_list(data) {
  return request({
    url: API.host + '/api/department/page',
    method: 'post',
    data: data || {}
  })
}
export function department_delete(id) {
  return request({
    url: API.host + `/api/department/delete/${id}`,
    method: 'delete'
  })
}
export function department_edit(data) {
  return request({
    url: API.host + '/api/department/edit',
    method: 'post',
    data: data || {}
  })
}
